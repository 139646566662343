import { useCallback, useState } from "react";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import { Nullable } from "types/cms/cms-types";
import { Question as FAQQuestion } from "types/cms/faq";
import FAQAccordion from "../FAQAcccordion/FAQAccordion";
import { FAQSectionStyled } from "./FAQSectionStyled";

interface Props {
  questions: Nullable<FAQQuestion[]>;
  title?: Nullable<string>;
  hasBlueBackground?: boolean;
  className?: string;
  variant?: "rewardsFAQ" | "FAQ";
  titleIndex?: Nullable<number>;
}

const FAQSection = ({ questions, title, hasBlueBackground, className, variant = "rewardsFAQ", titleIndex }: Props) => {
  const [openIndex, setOpenIndex] = useState<number>();
  const toggleOpenIndex = useCallback(
    (index: number) => (openIndex === index ? setOpenIndex(undefined) : setOpenIndex(index)),
    [openIndex]
  );

  return (
    <FAQSectionStyled className={`${className} ${hasBlueBackground && "oddSection"}`} variant={variant}>
      {title && (
        <Headings
          variant="headingMedium"
          fontSize={36}
          fontWeight={700}
          lineHeight={43}
          mobileFontSize={24}
          mobileLineHeight={24}
          className={`title ${"title" + titleIndex}`}
        >
          {title}
        </Headings>
      )}
      <div className="faqsContainer">
        {questions?.map(
          (question, index: number) =>
            question.Prompt &&
            question.Answer && (
              <FAQAccordion
                key={index}
                isExpanded={openIndex === index}
                toggleIsExpanded={() => toggleOpenIndex(index)}
                question={question.Prompt}
                answer={question.Answer}
                variant={variant}
              />
            )
        )}
      </div>
    </FAQSectionStyled>
  );
};

export default FAQSection;

import styled from "styled-components";

interface Props {
  size: "sm" | "md" | "lg" | "xl";
  color?: "blue" | "white";
}

const fontSizes = {
  xl: "18px",
  lg: "16px",
  md: "14px",
  sm: "12px",
};

const lineHeights = {
  xl: "22px",
  lg: "19px",
  md: "17px",
  sm: "14px",
};

const SerifBoldText = styled.p<Props>`
  ${(props) => `
        font-family: ${props.theme.font.serifHeavy};
        color: ${props.theme.color.core[props.color || "blue"]};
        font-size: ${fontSizes[props.size]};
        line-height: ${lineHeights[props.size]};
    `}
`;

export default SerifBoldText;

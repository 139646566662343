import styled from "styled-components";

export const JoinRewardCardStyled = styled.div`
  width: 100%;
  height: 568px;
  background: white;
  padding-left: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .joinRewardTextView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 495px;
    min-width: 495px;
    .horizontalLine {
      height: 3px;
      background: ${(props) => props.theme.color?.primary.foundersBlue};
      margin-top: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 400px;
      .horizontalLineText {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 2px 20px 2px 20px;
        overflow: hidden;
        background: ${(props) => props.theme.color?.core.white};
      }
    }
    .rewardDescription {
      line-height: 26px;
      text-align: center;
      margin-top: 42px;
      font-family: ${(props) => props.theme.font?.barlowSemiCondensed};
      font-weight: 500;
      .rewardLinkSpan {
        font-size: 22px;
        font-weight: 600;
        line-height: 26px;
        font-family: ${(props) => props.theme.font?.barlow};
        cursor: pointer;
        text-decoration: underline;
        white-space: nowrap;
      }
    }
    .rewardLink {
      display: none;
    }
    .imageWrapper {
      display: none;
    }
  }

  .rewardTitle {
    color: ${(props) => props.theme.color?.primary.tongueTorchRed};
    text-align: center;
  }
  .imageWrapperDesktop {
    position: relative;
    width: 854px;
    height: 568px;
    right: -50px;
  }
  .rewardButton {
    margin-top: 40px;
    padding: 0px 16px;
    width: 282px;
  }

  ${(props) => props.theme.responsive?.max.lg} {
    padding: 0px 40px 0px 40px;
    justify-content: center;
    min-height: 504px;
    height: auto;
    width: 390px;
    margin-top: -20px;
    .imageWrapperDesktop {
      display: none;
    }
    .joinRewardTextView {
      background: white;
      display: flex;
      align-items: center;
      flex-direction: column;
      min-width: auto;
      .rewardTitle {
        text-align: center;
      }
      .horizontalLine {
        width: 254px;
        height: 3px;
        background: ${(props) => props.theme.color?.primary.foundersBlue};
        margin-top: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        .horizontalLineText {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 2px 8px 2px 8px;
          overflow: hidden;
          background: white;
        }
      }
      .imageWrapper {
        display: block;
        position: relative;
        width: 272px;
        height: 182px;
      }
      .rewardDescription {
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        font-weight: 500;
        margin-bottom: 0px;
        margin-top: 0px;
        .rewardLinkSpan {
          display: none;
        }
      }
      .rewardLink {
        display: block;
        font-family: ${(props) => props.theme.font?.barlowSemiCondensed};
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        margin-top: 20px;
        color: ${(props) => props.theme.color?.primary.foundersBlue};
        text-decoration: underline;
        cursor: pointer;
      }
      .rewardButton {
        width: auto;
        padding: 0px 32px;
        margin-top: 16px;
      }
    }
  }
`;

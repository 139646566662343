import { ReactNode } from "react";
import ActionLink from "components/global/ActionLink/ActionLink";

interface Props {
  children: ReactNode;
  href: string;
}

const MarkdownLinkOverride = ({ children, href }: Props) => {
  return (
    <ActionLink to={href} size="lg" className="dropdownText">
      {children}
    </ActionLink>
  );
};

export default MarkdownLinkOverride;

import Image from "next/image";
import { CATEGORY_ITEM_DEFAULT_ICON } from "assets/images";
import { TieredReward } from "types/cms/rewards";
import SubHeadings from "components/atomic-components/atoms/typography/SubHeadings/SubHeadings";
import { TermsData } from "containers/Rewards/RewardsGuest/RewardsGuest";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import { RewardsGuestSliderItemStyled } from "./RewardsGuestSliderStyled";

interface Props extends TieredReward {
  onClick: (terms: TermsData) => void;
}

const RewardsGuestSliderItem = ({ Title, Terms, Image: image, Points, onClick }: Props) => {
  return (
    <RewardsGuestSliderItemStyled>
      <Image
        className="image"
        src={image?.url ?? CATEGORY_ITEM_DEFAULT_ICON}
        loader={() => image?.url || CATEGORY_ITEM_DEFAULT_ICON}
        alt={image?.alternativeText ?? "reward image"}
        width={336}
        height={225}
      />
      <div className="body">
        <SubHeadings variant="secondarySubHeading2" className="title">
          {Title}
        </SubHeadings>
        <div className="footer">
          <Paragraph
            variant="paragraphLogistics1"
            className="terms"
            fontWeight={600}
            onClick={() => onClick({ title: Title, terms: Terms })}
          >
            View Terms
          </Paragraph>
          <div className="points">
            <SubHeadings variant="rewardTilePoint" className="value">
              {Points}
            </SubHeadings>
            <Paragraph variant="paragraphHeading1" className="label" fontWeight={600}>
              pts
            </Paragraph>
          </div>
        </div>
      </div>
    </RewardsGuestSliderItemStyled>
  );
};

export default RewardsGuestSliderItem;

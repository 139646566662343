import Image from "next/image";
import { useSelector } from "react-redux";
import { RootState } from "src/types/types";
import { IC_ARROW_THIN_DOWN_SHARP_EDGES_RED } from "assets/images";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import FAQAccordionDropdown from "../FAQAccordionDropdown/FAQAaccordionDropdown";
import { FAQAccordionStyled } from "./FAQAccordionStyled";

interface Props {
  question: string;
  answer: string;
  isExpanded: boolean;
  variant?: "rewardsFAQ" | "FAQ";
  toggleIsExpanded: () => void;
}

const FAQAccordion = ({ question, answer, isExpanded, toggleIsExpanded, variant = "rewardsFAQ" }: Props) => {
  const { device } = useSelector((state: RootState) => ({
    device: state?.currentDevice.device,
  }));

  const height = device === "MOBILE" ? 8 : 14;
  const width = device === "MOBILE" ? 12 : 25;

  return (
    <FAQAccordionStyled isExpanded={isExpanded}>
      <div className="questionAndArrowWrapper">
        <Paragraph variant="tileDescription" className="faqQuestion">
          {question}
        </Paragraph>
        <div data-testid="faq-dropdown-handler" className="arrowIconContainer" onClick={toggleIsExpanded}>
          <Image src={IC_ARROW_THIN_DOWN_SHARP_EDGES_RED} alt="arrow icon" height={height} width={width} />
        </div>
      </div>
      <FAQAccordionDropdown isExpanded={isExpanded} text={answer} variant={variant} />
    </FAQAccordionStyled>
  );
};

export default FAQAccordion;

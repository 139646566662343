import styled from "styled-components";

export const FAQAccordionStyled = styled.div<{ isExpanded: boolean }>`
  margin-bottom: 44px;
  .questionAndArrowWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    .faqQuestion {
      font-size: 20px;
      font-weight: 600;
      line-height: 24.1px;
      text-align: left;
      color: ${(props) => props.theme.color.primary.foundersBlue};
    }
    .arrowIconContainer {
      cursor: pointer;
      transform: ${(props) => (props.isExpanded ? "rotate(180deg)" : "rotate(0deg)")};
      flex-shrink: 0;
      padding-right: 12px;
      padding-left: 10px;
      transition: transform 0.3s ease;
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    margin-bottom: 24px;
    .questionAndArrowWrapper {
      margin-bottom: 8px;
      .faqQuestion {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
      }
      .arrowIconContainer {
        padding-right: 8px;
      }
    }
  }
`;

import { ReactNode } from "react";
import SerifBoldText from "components/global/SerifBoldText/SerifBoldText";

interface Props {
  children: ReactNode;
}

const MarkdownParagraphOverride = ({ children }: Props) => {
  return (
    <SerifBoldText size="lg" className="dropdownText">
      {children}
    </SerifBoldText>
  );
};

export default MarkdownParagraphOverride;

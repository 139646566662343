import MarkdownComponent from "components/global/MarkdownComponent/MarkdownComponent";
import { FAQAccordionDropdownStyled } from "./FAQAccordionDropdownStyled";
import MarkdownParagraphOverride from "./components/MarkdownParagraphOverride/MarkdownParagraphOverride";
import MarkdownLinkOverride from "./components/MarkdownLinkOverride/MarkdownLinkOverride";

interface Props {
  text: string;
  isExpanded: boolean;
  variant?: "rewardsFAQ" | "FAQ";
}

const FAQAccordionDropdown = ({ text, isExpanded, variant = "rewardsFAQ" }: Props) => {
  return (
    <FAQAccordionDropdownStyled isExpanded={isExpanded} variant={variant}>
      <hr className="horizontalLine" />

      {isExpanded && (
        <MarkdownComponent
          markdown={text}
          className="markdownComponent"
          overrides={{
            span: { component: MarkdownParagraphOverride },
            p: { component: MarkdownParagraphOverride },
            a: { component: MarkdownLinkOverride },
          }}
        />
      )}
    </FAQAccordionDropdownStyled>
  );
};

export default FAQAccordionDropdown;

import { useSelector } from "react-redux";
import { RootState } from "src/types/types";
import { TieredReward } from "types/cms/rewards";
import { Nullable } from "types/cms/cms-types";
import { TermsData } from "containers/Rewards/RewardsGuest/RewardsGuest";
import Slider from "components/atomic-components/molecules/Slider/Slider";
import RewardsGuestSliderItem from "./RewardsGuestSliderItem";
import { RewardsGuestSliderStyled } from "./RewardsGuestSliderStyled";

interface Props {
  items?: Nullable<TieredReward[]>;
  openTermsModal: (terms: TermsData) => void;
}

const RewardsGuestSlider = ({ items, openTermsModal }: Props) => {
  const { device } = useSelector((state: RootState) => ({
    device: state.currentDevice.device,
  }));

  const isMobile = device === "MOBILE";
  const slideWidth = isMobile ? 240 : 336;
  const slideMargin = isMobile ? 8 : 16;

  // If there are no items to display return nothing
  if (!items) {
    return null;
  }

  return (
    <RewardsGuestSliderStyled>
      <Slider slideWidth={slideWidth} slideMargin={slideMargin}>
        <div className="ghost-card" />
        {items?.map((item) => {
          return <RewardsGuestSliderItem onClick={openTermsModal} key={item.Title} {...item} />;
        })}
        <div className="ghost-card" />
      </Slider>
    </RewardsGuestSliderStyled>
  );
};

export default RewardsGuestSlider;

import styled, { css } from "styled-components";
import { texturedBlueBackground } from "theme/globalClasses";
interface FAQSectionStyledProps {
  variant?: "rewardsFAQ" | "FAQ";
}
export const FAQSectionStyled = styled.div<FAQSectionStyledProps>`
  margin-block: 16px;
  position: relative;
  background: ${(props) => props.theme.color.core.white};
  ${(props) =>
    props.variant === "FAQ" &&
    css`
      background: ${(props) => props.theme.color?.primary.coleslawWhite};
    `}
  .faqsContainer {
    margin: 0 auto;
    width: min(90%, 780px);
    position: relative;

    .arrow {
      width: 93px;
      height: 207px;
      display: none;
      position: absolute;
      bottom: -213px;
      right: -162px;
    }
  }
  .title0 {
    margin-top: 60px !important;
    ${(props) => props.theme.responsive.max.lg} {
      margin-top: 32px !important;
    }
  }
  .title {
    ${(props) =>
      props.variant === "FAQ" &&
      css`
        margin-top: 84px;
        ${(props) => props.theme.responsive.max.lg} {
          margin-top: 32px;
        }
      `}
    margin-bottom: 35px;
    letter-spacing: 0.02em;
    text-align: center;
    ${(props) => props.theme.responsive.max.lg} {
      margin-top: 22px;
      ${(props) =>
        props.variant === "FAQ" &&
        css`
          margin-top: 48px;
        `}
    }
  }

  &:nth-of-type(odd),
  &.oddSection {
    &::after {
      content: "";
      display: block;
      z-index: -1;
      position: absolute;
      inset: 0;
      ${texturedBlueBackground}
    }

    .arrow {
      display: block;
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    &:nth-of-type(odd) .arrow,
    &.oddSection .arrow {
      display: none;
    }
    .title {
      margin-left: 44px;
      width: 342px;
      letter-spacing: 0.02em;
    }
  }
`;

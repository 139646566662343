import styled from "styled-components";

export const RewardsGuestSliderStyled = styled.div`
  & .slider-wrapper.center-items > .slide:first-child,
  & .slider-wrapper.start-items > .slide:first-child,
  & .slider-wrapper.center-items > .slide:last-child {
    width: 24px !important;
  }
`;

export const RewardsGuestSliderItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  overflow: hidden;

  .image {
    background-color: ${(props) => props.theme.color.primary.coleslawWhite};
  }

  .body {
    background-color: ${(props) => props.theme.color.limitedUse.darkRed4};
    color: ${(props) => props.theme.color.core.white};
    padding: 10px 16px;
    text-align: left;

    ${(props) => props.theme.responsive?.max.lg} {
      padding: 10px 12px 8px;
    }

    .title {
      color: inherit;
      margin-bottom: 26px;

      ${(props) => props.theme.responsive?.max.lg} {
        margin-bottom: 14px;
      }
    }
    .footer {
      display: flex;

      .terms {
        color: inherit;
        text-transform: uppercase;
        text-decoration: underline;
        text-decoration-line: underline;
        cursor: pointer;
        padding-top: 7px; /* offset the line-height of points subheading so they appear inline */

        ${(props) => props.theme.responsive?.max.lg} {
          padding: 0;
          align-self: center;
        }
      }
      .points {
        display: flex;
        margin-left: auto;
        .value {
          color: inherit;
        }
        .label {
          color: inherit;
          text-transform: lowercase;
          margin-top: 14px;
          margin-left: 2px;

          ${(props) => props.theme.responsive?.max.lg} {
            margin-top: 0;
            align-self: end;
          }
        }
      }
    }
  }
`;

import styled from "styled-components";

export const RewardsTermsStyled = styled.div`
  .modalContent {
    background: ${(props) => props.theme.color.limitedUse.darkRed4} !important;
    padding: 0 28px 60px 48px;
    position: relative;
    border-radius: 20px;
    scrollbar-width: 3px;
    scrollbar-color: ${(props) => props.theme.color.core.white} ${(props) => props.theme.color.secondary.veryDarkRed};

    &::-webkit-scrollbar {
      width: 3px;
      height: 211px;
    }

    &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.color.secondary.veryDarkRed};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.color.core.white};
      border-radius: 8px;
    }
    .closeBtnTerms {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      background-color: inherit;
      padding: 38px 5.29px 20px 0;
    }

    .title {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 24px;
    }

    .cmsTitle {
      :first-child {
        margin-top: 0;
      }

      margin-top: 25px;
      margin-bottom: 5px;
    }

    .legalTitle {
      &.heading {
        color: ${(props) => props.theme.color.primary.coleslawWhite};
        text-transform: uppercase;
        line-height: 26px;
        text-align: left;
      }
    }

    .legalText {
      color: white;
      text-align: left;
      font-family: ${(props) => props.theme.font.barlow};
      font-size: 16px;
      font-weight: 500;
      line-height: 19.2px;
    }

    .legalBlock {
      *:first-child {
        margin-bottom: 5px;
      }
    }

    .legalInfo {
      display: grid;
      gap: 25px;
      overflow-y: auto;
      padding-right: 20px;
    }
  }

  ${(props) => props.theme.responsive?.max.lg} {
    scrollbar-width: auto;
    scrollbar-color: ${(props) => props.theme.color.core.white} ${(props) => props.theme.color.core.red};
    &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.color.core.red};
    }

    .modalOverlay {
      z-index: 99999;
      top: 0;
      padding-top: 140px;
    }

    .modalContent {
      height: unset;
      width: 84%;
      border-radius: 20px;
      overflow-y: hidden;
      padding: 0 6px 84px 24px;

      .legalTitle {
        &.heading {
          line-height: 20px;
        }
      }
      .legalText {
        font-size: 12px;
        font-weight: 500;
        line-height: 14.4px;
      }
      .legalInfo {
        padding-left: 0;
        padding-right: 18px;
        max-height: 350px;
        gap: 17px;
      }

      .closeBtnTerms {
        padding: 32px 18px 16px 0;
      }
    }
  }
`;

import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPreviousRouteUrl } from "redux/reducer/Menu/MenuAction";
import { RootState } from "src/types/types";
import RewardsGuest from "./RewardsGuest/RewardsGuest";

const RewardsLoggedIn = dynamic(() => import("./RewardsLoggedIn/RewardsLoggedIn"), {
  ssr: false,
});

const Rewards = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(({ user }: RootState) => ({
    isAuthenticated: user.isAuthenticated,
  }));

  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  useEffect(() => {
    setIsUserAuthenticated(isAuthenticated);
    dispatch(setPreviousRouteUrl(""));
  }, [isAuthenticated]);

  return <div>{isUserAuthenticated ? <RewardsLoggedIn /> : <RewardsGuest />}</div>;
};

export default Rewards;

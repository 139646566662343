import styled, { css } from "styled-components";

interface Props {
  isExpanded?: boolean;
  variant?: "rewardsFAQ" | "FAQ";
}

export const FAQAccordionDropdownStyled = styled.div<Props>`
  position: relative;
  background: ${(props) => props.theme.color?.primary.coleslawWhite};
  ${(props) =>
    props.variant === "FAQ" &&
    css`
      background: ${(props) => props.theme.color.core.white};
    `}
  .horizontalLine {
    height: 5px;
    background: ${(props) => props.theme.color?.primary.coleslawWhite};
    ${(props) =>
      props.variant === "FAQ" &&
      css`
        background: ${(props) => props.theme.color.core.white};
      `}
    border: none;
  }
  .arrowIconContainer {
    position: absolute;
    cursor: pointer;
    transform: ${(props) => (props.isExpanded ? "rotate(180deg)" : "rotate(0deg)")};
    top: -29px;
    right: 30px;
  }

  .dropdownText {
    font-family: ${(props) => props.theme.font.barlow};
    color: black;
    background: ${(props) => props.theme.color?.primary.coleslawWhite};
    ${(props) =>
      props.variant === "FAQ" &&
      css`
        background: ${(props) => props.theme.color.core.white};
      `}
    padding: 23px 25px 28px;
    position: relative;
    z-index: 1;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
  }

  .markdownComponent {
    position: relative;
    padding: 10px 25px;
    z-index: 2;
    display: inline-table;
    font-weight: 500;
    font-family: ${(props) => props.theme.font.barlow};
    width: 780px;
    box-sizing: border-box;
    overflow-wrap: break-word;
    p.dropdownText:not(:last-of-type) {
      padding-bottom: 0;
    }
  }

  span.markdownComponent {
    padding-bottom: 28px;
    padding-top: 25px;
  }

  && {
    a {
      padding: 0px;
      margin-bottom: 5px;
      display: inline-block;
      text-decoration: underline;
      white-space: nowrap;
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.size.lg}) {
    .dropdownText {
      width: 342px;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
      padding: 16px;
    }
    .arrowIconContainer {
      top: -23px;
      right: 10px;
    }
    .markdownComponent {
      width: 342px;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
      padding: 0px;
    }
    span.markdownComponent {
      padding: 16px;
    }
    && {
      a {
        width: auto;
      }
    }
  }
`;

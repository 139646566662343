import Headings from "components/atomic-components/atoms/typography/Headings/Headings";

interface Props {
  device: string;
  titleMobile?: string | null;
  titleMobile2?: string | null;
  titleDesktopPart1?: string | null;
  titleDesktopPart2?: string | null;
}

const EarnPointsHeader = ({ device, titleMobile, titleMobile2, titleDesktopPart1, titleDesktopPart2 }: Props) => {
  if (device === "DESKTOP") {
    return (
      <>
        <Headings variant="primaryHeading2">{titleDesktopPart1}&nbsp;</Headings>
        <Headings variant="primaryHeading1" fontSize={51} mobileFontSize={24} className="redPartOfHeading">
          {titleDesktopPart2}
        </Headings>
      </>
    );
  }

  return (
    <>
      <Headings variant="primaryHeading2" mobileFontSize={28} mobileLineHeight={26} className="mobileHeader">
        {titleMobile}
      </Headings>
      <Headings variant="primaryHeading1" fontSize={51} mobileFontSize={24} className="redPartOfHeading">
        {titleMobile2}
      </Headings>
    </>
  );
};

export default EarnPointsHeader;

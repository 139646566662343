import styled from "styled-components";

interface MenuItemProps {
  width?: number | string;
}

interface SliderWrapperProps {
  isItemLeftAlligned?: boolean;
  device?: string;
  stylePartialSlides?: boolean;
  stylePartialSlidesMobile?: boolean;
}

export const SliderWrapper = styled.div<SliderWrapperProps>`
  position: relative;
  width: 100%;
  overflow: hidden;

  .slider-container {
    position: relative;
    width: 100vw;
    overflow: hidden;
  }

  .slider-container.rtl {
    direction: rtl;
  }

  .slider-wrapper {
    padding: 10px 0;
    scroll-behavior: smooth;
    display: flex;
    overflow-x: auto;
    scroll-snap-type: ${(props) => (props.device === "DESKTOP" ? "x mandatory" : "none")};

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &.center-items {
      justify-content: center;
    }

    &.start-items {
      justify-content: start;
    }

    ::-webkit-scrollbar {
      display: none; /* Webkit */
    }

    .slide {
      scroll-snap-align: start;
      ${(props) =>
        props.stylePartialSlides &&
        `&.partial-slide {
        opacity: 0.2;
        transition: opacity 0.4s;
        ${props.theme.responsive.max.lg} {
          & {
            /* only show opacity on large screens when arrows appear or is prop stylePartialSlidesMobile is there */
            opacity: ${props.stylePartialSlidesMobile ? 0.2 : 1};
          }
        }
      }`}
    }

    & > .slide:last-child {
      display: block;
      height: 20px !important;
      margin-right: 0px !important;
      width: 120px !important;
    }

    & > .slide:first-child {
      /* first ghost element */
      display: block;
      height: 20px !important;
      margin-right: 0px !important;
      width: calc((100% - ${(props) => props.theme.contentContainerWidth.lg}) / 2) !important;

      @media only screen and (min-width: 1480px) {
        width: calc((100% - ${(props) => props.theme.contentContainerWidth.xl}) / 2) !important;
      }
      ${(props) =>
        props.isItemLeftAlligned &&
        `
      @media screen and (min-width: 1920px) {
        width: 120px !important;
      }
      `}
    }
  }

  .next-slide,
  .prev-slide {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    z-index: 9;
    background-color: transparent;
    cursor: pointer;
  }

  .next-slide {
    right: 50px;
  }

  .prev-slide {
    left: 50px;
  }
  .recent-order-prev-slide {
    bottom: 63px;
    left: 20px;
    top: unset;
  }

  .recent-order-next-slide {
    bottom: 63px;
    right: 20px;
    top: unset;
  }

  ${(props) => props.theme.responsive.max.lg} {
    .next-slide {
      display: none;
    }

    .prev-slide {
      display: none;
    }

    .slider-wrapper {
      & > .slide:first-child {
        width: 30px !important;
      }
      & > .slide:last-child {
        width: 30px !important;
      }
    }
  }
  .recentOrder {
    width: auto !important;
  }
`;

export const MenuItemWrapper = styled.div<MenuItemProps>`
  min-height: 30rem;
  border: 2px solid black;
  margin-left: 5rem;
  padding: 2rem;
  width: ${(props) => props?.width ?? "100%"};
`;

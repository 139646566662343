import Image from "next/image";
import React from "react";
import { IC_CHEVRON_REBRAND_ROUNDED_RED_RIGHT_ARROW, IC_CHEVRON_REBRAND_RIGHT_ARROW } from "assets/images";
import { SLIDER_CARD_TYPE } from "constants/constant";

export type ImageVariantProps = "CHEVRON_RED_ROUNDED_FIRST" | "CHEVRON_BLUE_ROUNDED_FIRST";

interface NextSlideArrowProps {
  onClick: () => void;
  type: "ZAX_CARD" | "RECENT_ORDER" | string;
  imageVariant?: ImageVariantProps;
}

const getImageVariant = ({ variant }: { variant: ImageVariantProps }) => {
  switch (variant) {
    case "CHEVRON_RED_ROUNDED_FIRST":
      return IC_CHEVRON_REBRAND_ROUNDED_RED_RIGHT_ARROW;
    case "CHEVRON_BLUE_ROUNDED_FIRST":
      return IC_CHEVRON_REBRAND_RIGHT_ARROW;
    default:
      return IC_CHEVRON_REBRAND_ROUNDED_RED_RIGHT_ARROW;
  }
};

const NextSlideArrow = React.forwardRef<HTMLButtonElement, NextSlideArrowProps>(({ onClick, type = "ZAX_CARD", imageVariant }, ref) => {
  return (
    <button
      onClick={onClick}
      ref={ref}
      tabIndex={-1}
      className={type !== SLIDER_CARD_TYPE.ZAX_CARD ? "next-slide recent-order-next-slide" : "next-slide"}
    >
      <Image
        src={getImageVariant({
          variant: imageVariant ?? "CHEVRON_RED_ROUNDED_FIRST",
        })}
        alt="slider-arrow-right"
        height="46px"
        width="46px"
      />
    </button>
  );
});

NextSlideArrow.displayName = "NextSlideArrow";
export default NextSlideArrow;

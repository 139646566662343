import CloseButton from "components/global/Buttons/CloseButton/CloseButton";
import Modal from "components/global/Modal/Modal";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import { Nullable } from "types/cms/cms-types";
import { Rewards, RewardTerms, Terms } from "src/types/types";
import { RewardsTermsStyled } from "./RewardsTermsStyled";

interface Props {
  onClose: () => void;
  cmsRewardsTitle?: Nullable<string>;
  cmsRewardsTerms?: Nullable<string>;
  rewardTerms?: Rewards | RewardTerms | null;
  rewardName?: string;
}

const RewardsTerms = ({ onClose, rewardTerms, cmsRewardsTitle, cmsRewardsTerms }: Props) => {
  return (
    <RewardsTermsStyled>
      <Modal isOpen={true} overlayClassName="modalOverlay" className="modalContent">
        <CloseButton variant="tenth" className="closeBtnTerms" onClick={onClose} showText={false} />
        <div className="title">
          {cmsRewardsTitle && (
            <Headings variant="primaryHeading3" fontSize={24} mobileFontSize={18} className="legalTitle heading">
              {cmsRewardsTitle}
            </Headings>
          )}
          {rewardTerms?.name && (
            <Headings variant="primaryHeading3" fontSize={24} mobileFontSize={18} className="legalTitle heading">
              {rewardTerms.name}
            </Headings>
          )}
        </div>
        <div className="legalInfo">
          {cmsRewardsTerms && (
            <Paragraph variant="secondaryParagraph1" className="legalText">
              {cmsRewardsTerms}
            </Paragraph>
          )}
          {rewardTerms?.terms?.map((term: Terms, index: number) => {
            return (
              <div className="legalBlock" key={index}>
                {term?.title && (
                  <Headings variant="primaryHeading3" fontSize={24} mobileFontSize={18} className="legalTitle">
                    {term.title}
                  </Headings>
                )}
                {term?.desc && (
                  <Paragraph variant="secondaryParagraph1" className="legalText">
                    {term.desc}
                  </Paragraph>
                )}
              </div>
            );
          })}
        </div>
      </Modal>
    </RewardsTermsStyled>
  );
};

export default RewardsTerms;

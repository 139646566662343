import Image from "next/image";
import React from "react";
import { IC_CHEVRON_REBRAND_ROUNDED_RED_LEFT_ARROW, IC_CHEVRON_REBRAND_LEFT_ARROW } from "assets/images";
import { SLIDER_CARD_TYPE } from "constants/constant";

type ImageVariantProps = "CHEVRON_RED_ROUNDED_FIRST" | "CHEVRON_BLUE_ROUNDED_FIRST";

interface PrevSlideArrowProps {
  onClick: () => void;
  type: "ZAX_CARD" | "RECENT_ORDER" | string;
  imageVariant?: ImageVariantProps;
}

const getImageVariant = ({ variant }: { variant: ImageVariantProps }) => {
  switch (variant) {
    case "CHEVRON_RED_ROUNDED_FIRST":
      return IC_CHEVRON_REBRAND_ROUNDED_RED_LEFT_ARROW;
    case "CHEVRON_BLUE_ROUNDED_FIRST":
      return IC_CHEVRON_REBRAND_LEFT_ARROW;
    default:
      return IC_CHEVRON_REBRAND_ROUNDED_RED_LEFT_ARROW;
  }
};

const PrevSlideArrow = React.forwardRef<HTMLButtonElement, PrevSlideArrowProps>(({ onClick, type = "ZAX_CARD", imageVariant }, ref) => {
  return (
    <button
      onClick={onClick}
      ref={ref}
      className={type !== SLIDER_CARD_TYPE.ZAX_CARD ? "prev-slide recent-order-prev-slide" : "prev-slide"}
      tabIndex={0}
    >
      <Image
        src={getImageVariant({
          variant: imageVariant ?? "CHEVRON_RED_ROUNDED_FIRST",
        })}
        alt="slider-arrow-left"
        height="46px"
        width="46px"
      />
    </button>
  );
});

PrevSlideArrow.displayName = "PrevSlideArrow";
export default PrevSlideArrow;
